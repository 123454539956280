import { Icon } from '../Icon';
import styles from './SearchBar.module.scss';

export const SearchBar = () => {
  return (
    <div>
      <form className={styles.form} target="_top" action="/suche">
        <Icon className={styles.icon} name="search" size={20} viewBox="0 0 20 20" />
        <input className={styles.input} type="search" name="q" placeholder="Suche" autoComplete="off" />
      </form>
    </div>
  );
};
